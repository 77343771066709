import React from "react";
import { string, shape, arrayOf, bool } from "prop-types";
import { motion } from "framer-motion";
import { isMobileOnly } from "react-device-detect";
import Heading from "../../Base/HeadingBuilder";
import ProductCard from "../../Base/ProductCard";
import { viewports } from "../../../style-vars";
import useStartMotion from "../../../hooks/use-start-motion";

const variants = {
  hidden: { opacity: 0, y: 50 },
  visible: {
    opacity: 1,
    y: 0,
  },
};

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
  },
};

const ProductsGrid = ({ className, title, products, animations }) => {
  const { ref, controls } = useStartMotion();

  return (
    <section
      className={`
        products-grid
        relative max-w-1440 w-full
        px-4 xl:px-40
        ${className}
    `}
      ref={ref}
      data-cy="products-grid"
    >
      <motion.div
        animate={controls}
        initial={isMobileOnly || !animations ? "visible" : "hidden"}
        variants={variants}
        transition={{ duration: 0.8 }}
      >
        <Heading
          level={2}
          injectionType={1}
          className="mb-11 lg:mb-20 lg:text-center xl:max-w-90-percent lg:mx-auto"
          withBlueSymbol
        >
          {title}
        </Heading>
      </motion.div>

      <motion.div
        className={` 
        grid gap-6 xl:gap-y-16 place-items-center
        grid-cols-1 md:grid-cols-2 xl:grid-cols-3 
       `}
        animate={controls}
        initial={isMobileOnly || !animations ? "visible" : "hidden"}
        variants={fadeInVariants}
        transition={{ delay: 0.4, duration: 0.8 }}
      >
        <ProductCard
          className="xl:top-60"
          key={products[0].title}
          {...products[0]}
        />
        <ProductCard className="" key={products[1].title} {...products[1]} />
        <ProductCard
          className="xl:top-60"
          key={products[2].title}
          {...products[2]}
        />
        <ProductCard
          className="xl:top-60"
          key={products[3].title}
          {...products[3]}
        />
        <ProductCard className="" key={products[4].title} {...products[4]} />
        <ProductCard
          className="xl:top-60"
          key={products[5].title}
          {...products[5]}
        />
        <ProductCard
          className="xl:col-start-2"
          key={products[6].title}
          {...products[6]}
        />
      </motion.div>

      <style jsx>{`
        @media ${viewports.xlOrBigger} {
          :global(.products-grid .product-card) {
            max-height: 460px !important;
          }
        }
      `}</style>
    </section>
  );
};

ProductsGrid.propTypes = {
  className: string,
  title: string.isRequired,
  products: arrayOf(
    shape({
      title: string.isRequired,
      linkTo: string.isRequired,
      imageName: string.isRequired,
    })
  ),
  animations: bool,
};

ProductsGrid.defaultProps = {
  className: "",
  products: [],
  animations: true,
};

export default ProductsGrid;
